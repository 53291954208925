import React from "react";
import { Link } from "react-router-dom";
import Image from "material-ui-image";
import { useLogo } from "../../services/hooks";


import { Toolbar, Hidden, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "flex-end",
  },
  logoContainer: {
    padding: theme.spacing(2)
  }
}));

export default function DesktopNav(props) {
  const classes = useStyles();
  const logo = useLogo();
  const { links } = props;

  const linkButtons = links.map((link) => {
    return (
      <Grid key={link.text} item>
        <Button
          className={classes.button}
          color="secondary"
          href={link.url}
          children={link.text}
        />
      </Grid>
    );
  });

  return (
    <Hidden smDown>
      <Toolbar>
        <Grid item xs={2} className={classes.logoContainer}>
          {logo.image && (
            <Link to="/">
              <Image
                src={"https://api.infinitypartnerscapital.com" + logo.longLogo.url}
                aspectRatio={1040 / 200}
                color="inherit"
              />
            </Link>
          )}
        </Grid>
        <Grid item container xs={10} className={classes.toolbar}>
          {linkButtons}
        </Grid>
      </Toolbar>
    </Hidden>
  );
}
