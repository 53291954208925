import React from "react";

export default class Select extends React.Component {
  render() {
    //Destructure props for easier reading
    const {
      id,
      handleChange,
      label,
      name,
      value,
      options,
      isDisabled,
    } = this.props;

    //Split out options
    const optionsList = options.map((option, index) => {
      return <option key={index}>{option}</option>;
    });

    return (
      <div className="form-group">
        <label htmlFor={id} className="col-12">
          {label}
        </label>
        <div className="col-12">
          <select
            className="form-control"
            id={id}
            onChange={handleChange}
            name={name}
            value={value}
            disabled={isDisabled}
          >
            {optionsList}
          </select>
        </div>
      </div>
    );
  }
}
