import React, { useState, useEffect } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core";

import { PortfolioCard } from "./";

export default function PortfolioList() {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    axios
      .get("https://www.api.infinitypartnerscapital.com/companies")
      .then((res) => {
        setCompanies(res.data);
      });
  }, []);

  const portfolioCards = companies.map((company) => {

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={company._id}>
        <PortfolioCard
          title={company.title}
          description={company.description}
          imgSrc={"https://api.infinitypartnerscapital.com" + company.image.url}
          url={company.url}
        />
      </Grid>
    );
  });

  return portfolioCards;
}
