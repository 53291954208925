import React from "react";
import { Link } from "react-router-dom";

import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
  },
  text: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
    },
  },
}));

export default function TextBox() {
  const classes = useStyles();
  return (
    <Grid item container className={classes.root}>
      <Grid item xs={12} lg={8}>
        <Typography variant="h4" className={classes.text} gutterBottom>
          Tell us about your journey or find out the value of your business
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} lg={6}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/valuation"
          size="large"
          fullWidth
        >
          Get value
        </Button>
      </Grid>
    </Grid>
  );
}
