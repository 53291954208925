import React from "react";
import axios from "axios";

//import components
import TextInput from "../common/TextInput";
import NumberInput from "../common/NumberInput";
import Select from "../common/Select";
import TextArea from "../common/TextArea";

export default class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      indOptions: [],
    };

    this.getIndustries = this.getIndustries.bind(this);
  }

  //API CALLS ===============================================
  getIndustries() {
    axios.get("/api/industries").then((res) => {
      const indOptions = res.data.industries.map((industry) => {
        return industry.name;
      });

      this.setState({
        indOptions: indOptions,
      });
    });
  }

  //LIFECYCLE METHODS =======================================
  componentDidMount() {
    this.getIndustries();
  }

  render() {
    //Set options for company types
    const typeOptions = [
      "Close Corporation",
      "Company PTY LTD",
      "Partnership",
      "Sole Proprietor",
      "Other",
    ];

    //Destructure props and state
    const { handleChange, company } = this.props;
    const { indOptions } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <TextInput
            id="compName"
            handleChange={handleChange}
            label="Company Name"
            name="name"
            placeholder="Enter the name of your booming enterprise"
            type="text"
            value={company.name}
          />
          <Select
            id="compType"
            handleChange={handleChange}
            label="Company Type"
            name="type"
            options={typeOptions}
            value={company.type}
          />
          <TextArea
            id="compDescription"
            handleChange={handleChange}
            label="Company Description"
            name="description"
            rows={3}
            value={company.description}
          />
          <Select
            id="compIndustry"
            handleChange={handleChange}
            label="Company Industry"
            name="industry"
            options={indOptions}
            value={company.industry}
          />
          <NumberInput
            id="compEmployees"
            handleChange={handleChange}
            label="No. of Employees"
            name="employees"
            placeholder="How many employees do you have"
            type="number"
            value={company.employees}
          />
        </div>
      </div>
    );
  }
}
