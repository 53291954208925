import React from "react";

//import components
import TextInput from "../common/TextInput";

export default class ContactDetails extends React.Component {
  render() {

    const {contact, handleChange} = this.props;

    return (
      <div className="row">
        <div className="col-12">
          <TextInput
            id="fName"
            handleChange={handleChange}
            label="First Name"
            name="fName"
            placeholder="John"
            type="text"
            value={contact.fName}
          />

          <TextInput
            id="lName"
            handleChange={handleChange}
            label="Last Name"
            name="lName"
            placeholder="Smith"
            type="text"
            value={contact.lName}
          />

          <TextInput
            id="userEmail"
            handleChange={handleChange}
            label="Email"
            name="email"
            placeholder="name@example.com"
            type="email"
            value={contact.email}
          />

          <TextInput
            id="userPhone"
            handleChange={handleChange}
            label="Phone"
            name="phone"
            placeholder="083 555 7485"
            type="text"
            value={contact.phone}
          />
        </div>
      </div>
    );
  }
}
