import React from "react";
import axios from "axios";

//import components
import MultipleCard from "./MultipleCard";

export default class Results extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ratios: [],
    };

    this.getRatios = this.getRatios.bind(this);
  }

  //API CALLS =======================================
  getRatios() {
    axios.get("/api/ratios/" + this.props.industry).then((res) => {
      this.setState({
        ratios: res.data.ratios,
      });
    });
  }

  //LIFECYCLE METHODS ================================
  componentDidMount() {
    this.getRatios();
  }

  render() {
    const { industry, financials } = this.props;
    const chosenRatio = ["EV/Sales", "EV/EBITDA", "PBV", "EV/ Invested Capital"];

    const cards = chosenRatio.map((ratio, index) => {
      let test = this.state.ratios.filter((i) => {
        if (i.name === ratio)
          return true;
      })
      return (
        <MultipleCard
          key={index}
          ratioDetails={test}
          financials={financials}
          name={ratio}
        />
      )
    })

    const resultsOutput = {};

    return (
      <div className="row">
        <div className="col-12">
          <h2 >Valuation Results</h2>
        </div>
        {cards}

      </div>
    );
  }
}
