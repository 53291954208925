import React from "react";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { Header } from "../../common";
import { ServiceList } from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(135deg, " +
      theme.palette.primary.dark +
      ", " +
      theme.palette.primary.main +
      ");",
    padding: theme.spacing(3),
    color: theme.palette.secondary.main,
    alignContent: "space-around",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
    },
  },
  cardContainer: {
    justifyContent: "center",
  },
}));

export default function Services() {
  const classes = useStyles();
  return (
    <Grid id="services" item container className={classes.root}>
      <Grid item container>
        <Header title="Our Services" subtitle="We have a range of expertise" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        lg={10}
        className={classes.cardContainer}
        spacing={6}
      >
        <ServiceList />
      </Grid>
    </Grid>
  );
}
