import React from "react";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function RadioButtons(props) {
  const { label, handleChange, name, options, value } = props;

  const optionsList = options.map((option, index) => (
    <FormControlLabel
      key={option._id}
      value={option._id}
      label={option.title}
      control={<Radio />}
    />
  ));

  return (
    <FormControl component="fieldset" margin="normal" fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {optionsList}
      </RadioGroup>
    </FormControl>
  );
}
