//Add react library
import React from "react";

//import react components

export default class MultipleCard extends React.Component {
  render() {
    function abbreviateNumber(num, fixed) {
      if (num === null) {
        return null;
      } // terminate early
      if (num === 0) {
        return "0";
      } // terminate early
      fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
      var b = num.toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c =
          k < 1
            ? num.toFixed(0 + fixed)
            : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ["", "K", "M", "B", "T"][k]; // append power
      return e;
    }

    const { ratioDetails, financials, name } = this.props;
    let metric = 0;
    switch (name) {
      case "EV/Sales":
        metric = financials.revenue;
        break;
      case "EV/EBITDA":
        metric = financials.ebitda;
        break;
      case "PBV":
        metric = financials.bookValue;
        break;
      case "EV/ Invested Capital":
        metric = financials.investedCapital;
        break;
    }

    const values = ratioDetails.map((details) => {
      if (details.country === "South Africa") {
        return (
          <h2 key={details._id} className="card-title">
            {details.country} : {"R "} {abbreviateNumber(details.value * metric, 0)}
          </h2>
        );
      } else {
        return (
          <h5 key={details._id} className="card-title">
            {details.country} : {"R "} {abbreviateNumber(details.value * metric, 0)}
          </h5>
        );
      }
    });

    values.reverse();

    return (
      <div className="col-12 col-md-6 text-dark">
        <div className="card m-2">
          <h3 className="card-header bg-dark text-light">{name}</h3>
          <div className="card-body">
            <h3 className="card-title"></h3>
            {values}
          </div>
        </div>
      </div>
    );
  }
}
