//Add react library
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tinygradient from "tinygradient";

import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

//import react components
import { Header } from "../common";
import { BlogList } from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: tinygradient([
      { color: theme.palette.primary.light, pos: 0 },
      { color: theme.palette.primary.light, pos: 0.5 },
      { color: theme.palette.primary.dark, pos: 0.5 },
    ]).css("linear", "180deg"),
    minHeight: "90vh",
    alignContent: "flex-start",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
  },
  cardContainer: {
    justifyContent: "center",
  },
}));

export default function Blog() {
  const classes = useStyles();

  return (
    <Grid item container className={classes.root}>
      <Grid item container xs={12}>
        <Header title="Our articles" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={10}
        className={classes.cardContainer}
        spacing={4}
      >
        <BlogList />
      </Grid>
    </Grid>
  );
}
