import React from "react";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { CTA, Hero } from "./";

const useStyles = makeStyles((theme) => ({
  jumbotron: {
    background: "#D1D7DB",
    padding: theme.spacing(3),
    minHeight: "75vh",
    [theme.breakpoints.up("lg")]: {
      minHeight: "90vh",
    },
  },
  cta: {
    order: 2,
    [theme.breakpoints.up("lg")]: {
      order: 1,
    },
  },
  hero: {
    order: 1,
    [theme.breakpoints.up("lg")]: {
      order: 2,
    },
  },
}));

export default function Jumbotron() {
  const classes = useStyles();
  return (
    <Grid id="home" item container className={classes.jumbotron}>
      <Grid item container xs={12} lg={6} className={classes.cta}>
        <CTA />
      </Grid>
      <Grid item container xs={12} lg={6} className={classes.hero}>
        <Hero />
      </Grid>
    </Grid>
  );
}
