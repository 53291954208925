import React, { Component } from 'react'

export default class Button extends Component {

  render() {
    return (
        <div className="col-6">
          <button className="btn btn-primary btn-block" type="button" onClick={this.props.onClick}>
            {this.props.text}
          </button>
        </div>

    )
  }
}
