import React from "react";
import { Link } from "react-router-dom";
import Image from "material-ui-image";
import { useLogo } from "../../services/hooks";

import { Grid, Typography, Button, Divider } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Facebook, LinkedIn, Instagram } from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "30vh",
    background: theme.palette.secondary.main,
    alignContent: "space-between",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
}));

export default function Footer() {
  const classes = useStyles();
  const logo = useLogo();

  return (
    <Grid item container xs={12} className={classes.root}>
      <Grid item xs={12} sm={8} md={3}>
        {logo.longLogo && (
          <Link to="/">
            <Image
              src={"https://api.infinitypartnerscapital.com" + logo.longLogo.url}
              aspectRatio={1040 / 200}
              color="inherit"
            />
          </Link>
        )}
      </Grid>
      <Grid item container xs={12} justify="center">
        <Button color="primary" size="small" href="/#services">
          SERVICES
        </Button>
        <Button color="primary" size="small" href="/team">
          TEAM
        </Button>
        <Button color="primary" size="small" href="/about">
          ABOUT
        </Button>
        <Button color="primary" size="small" href="/contact">
          CONTACT
        </Button>
      </Grid>
      <Grid item container xs={12} sm={8} justify="center">
        <IconButton color="primary" children=<Facebook /> />
        <IconButton color="primary" children=<LinkedIn /> />
        <IconButton color="primary" children=<Instagram /> />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Divider />
      </Grid>
      <Grid item container xs={12} sm={8} justify="center">
        <Typography variant="caption" color="primary" align="center">
          Copyright 2021
        </Typography>
      </Grid>
    </Grid>
  );
}
