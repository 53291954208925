import React, { useState, useEffect } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core";

import { ServiceCard } from "./";

export default function ServiceList() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    axios
      .get("https://www.api.infinitypartnerscapital.com/services")
      .then((res) => {
        setServices(res.data);
      });
  }, []);

  const serviceList = services.map((service, index) => {
    return (
      <Grid key={service._id} item container xs={12} sm={6} md={4}>
        <ServiceCard
          imgSrc={"https://api.infinitypartnerscapital.com" + service.image.url}
          title={service.title}
          serviceList={service.serviceList}
        />
      </Grid>
    );
  });

  return serviceList;
}
