import React from "react";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    alignContent: "center",
  },
  video: {
    width: "80%",
    margin: "0 auto",
  },
}));

export default function Hero() {
  const classes = useStyles();
  return (
    <Grid item container alignItems="center" className={classes.root}>
      <video
        id="background-video"
        loop
        autoPlay
        preload="auto"
        className={classes.video}
      >
        <source src="https://www.api.infinitypartnerscapital.com/uploads/Hero_GIF_Old_4abc743e23.mp4" />
        Your browser does not support the video tag.
      </video>
    </Grid>
  );
}
