import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import GraphikRegular from "./GraphikRegular.otf";

const graphikRegular = {
  fontFamily: "GraphikRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "500",
  src: `
   local('GraphikRegular'),
   url(${GraphikRegular}) format('opentype')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};


let theme = createMuiTheme({
  typography: {
    fontFamily: "GraphikRegular, sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [graphikRegular],
      },
    },
  },
  palette: {
    common: {
      black: "#000000",
      white: "#fff",
    },
    primary: {
      main: "#203d51",
    },
    secondary: {
      main: "#bed5e6",
    },
    background: {
      default: "#080f14",
      paper: "#e2f3f4"
    },
    tonalOffset: 0.3,
  },
});

theme = responsiveFontSizes(theme);
export default theme;
