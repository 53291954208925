//Add react library
import React from "react";

import { Grid } from "@material-ui/core";

//import react components
import { Jumbotron, Services, About, Team, Contact } from "./";

export default function Main() {
  return (
    <Grid container>
      <Jumbotron />
      <Services />
      <About />
      <Team />
      <Contact />
    </Grid>
  );
}
