import React from "react";

import { Grid, Typography, IconButton } from "@material-ui/core";

import { Card, CardContent, CardActions } from "@material-ui/core";

import { LinkedIn } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  cardImage: {
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    maxWidth: "40%",
    margin: "0 auto",
    display: "block",
    borderRadius: "50%",
  },
  iconButton: {
    margin: "auto",
  },
}));

export default function TeamCard(props) {
  const classes = useStyles();
  const { imgSrc, name, role, specialityList, description, linkedIn } = props;

  const specialtiesText = specialityList.map((speciality, index, arr) => {
    if (arr.length - 1 === index) {
      return speciality.text;
    } else {
      return speciality.text + " | ";
    }
  });

  return (
    <Card className={classes.card} raised>
      <CardContent className={classes.cardImage}>
        <img className={classes.image} src={imgSrc} alt="" />
      </CardContent>
      <CardContent className={classes.cardBody}>
        <Typography variant="h5" align="center" color="primary">
          {name}
        </Typography>
        <Typography variant="h6" align="center" color="primary" gutterBottom>
          {role}
        </Typography>
        <Typography
          display="block"
          variant="overline"
          align="center"
          gutterBottom
        >
          {specialtiesText}
        </Typography>
        <Typography variant="caption" component="p" align="center">
          {description}
        </Typography>
        <IconButton
          color="primary"
          className={classes.iconButton}
          children={<LinkedIn />}
          href={linkedIn}
        />
      </CardContent>
    </Card>
  );
}
