import React, { Component } from "react";

import Button from "./Button";

export default class ButtonGroup extends Component {
  render() {
    //Destructure props
    const { currentStep, handleNext, handlePrev, handleSubmit } = this.props;

    if (currentStep === 1) {
      return (
        <div className="row">
          <div className="offset-6"></div>
          <Button text="Next" onClick={handleNext} />
        </div>
      );
    } else if (currentStep === 4) {
      return (
        <div className="row">
          <Button text="Prev" onClick={handlePrev} />
          <Button text="Submit" onClick={handleSubmit} />
        </div>
      );
    } else {
      return (
        <div className="row">
          <Button text="Prev" onClick={handlePrev} />
          <Button text="Next" onClick={handleNext} />
        </div>
      );
    }
  }
}
