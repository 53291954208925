import React from "react";

import { Grid, Typography, Divider } from "@material-ui/core";

import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(3)
  }
}))

export default function Header(props) {
  const classes = useStyles();
  const { title, subtitle } = props;
  return (
    <Grid item container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center" gutterBottom>{subtitle}</Typography>
      </Grid>
    </Grid>
  );
}
