import axios from "axios";

export function getLogo() {
  return axios
    .get("https://api.infinitypartnerscapital.com/logo")
}

export function getAbout() {
  return axios
    .get("https://api.infinitypartnerscapital.com/about")
}

export function getCompanyInfo() {
  return axios
    .get("https://api.infinitypartnerscapital.com/company-info")
}
