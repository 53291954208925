//Add react library
import React from "react";
import ReactMarkdown from "react-markdown";
import tinygradient from "tinygradient";

import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Header } from "../common";
import { useAbout } from "../../services/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    color: theme.palette.secondary.main,
    backgroundImage: tinygradient([
      { color: theme.palette.secondary.light, pos: 0 },
      { color: theme.palette.primary.dark, pos: 0.8 },
      { color: theme.palette.primary.dark, pos: 1},
    ]).css("linear", "180deg"),
    alignContent: "space-around",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  titleText: {
    color: theme.palette.primary.main,
  },
  coverImage: {
    maxHeight: "50vh",
    overflow: "hidden",
    width: "100%",
    objectFit: "cover",
  },
}));

export default function About() {
  const classes = useStyles();
  const about = useAbout();

  return (
    <Grid item container xs={12} className={classes.root}>
      <Grid item xs={12} className={classes.titleText}>
        <Header title={about.title} subtitle={about.subtitle} />
      </Grid>
      {about.coverImage && (
        <Grid item xs={12} md={8} lg={6}>
          <img
            src={
              "https://api.infinitypartnerscapital.com" + about.coverImage.url
            }
            alt="Cover picture"
            className={classes.coverImage}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={10} md={8}>
        <ReactMarkdown>{about.content}</ReactMarkdown>
      </Grid>
      <Grid item xs={12} md={8}>
        <ReactMarkdown>{about.career}</ReactMarkdown>
      </Grid>
      <Grid item container xs={12} justify="center">
        <Button variant="outlined" color="secondary" href="/#contact">
          Contact Us
        </Button>
      </Grid>
    </Grid>
  );
}
