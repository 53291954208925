import React from "react";

import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
//import Components
import { Form } from "./";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

export default function ContactForm() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary" gutterBottom>
            Keep in touch!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Form />
        </Grid>
      </Grid>
    </Paper>
  );
}
