import React from "react";

import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Header } from "../../common";
import { TeamList } from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(3),
    alignContent: "space-around",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
    },
  },
  cardContainer: {
    justifyContent: "center",
  },
}));

export default function Team() {
  const classes = useStyles();
  return (
    <Grid id="team" item container className={classes.root} spacing={2}>
      <Grid item container xs={12}>
        <Header title="Our team" subtitle="We have a wide range of expertise" />
      </Grid>
      <Grid
        item
        container
        md={10}
        className={classes.cardContainer}
        spacing={3}
      >
        <TeamList />
      </Grid>
      <Grid item container xs={12} justify="center">
        <Button color="secondary" href="/team">
          Learn more
        </Button>
      </Grid>
    </Grid>
  );
}
