import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

//Import components
import Steps from "./steps/Steps";
import ButtonGroup from "./buttongroup/ButtonGroup";

export default class MultiStepForm extends React.Component {
  constructor() {
    super();

    this.state = {
      currentStep: 1,
      redirect: false,
      company: {
        name: "",
        type: "",
        description: "",
        industry: "",
        employees: "",
      },
      financials: {
        year: "",
        revenue: "",
        ebitda: "",
        netProfit: "",
        bookValue: "",
        investedCapital: "",
      },
      contact: {
        fName: "",
        lName: "",
        email: "",
        phone: "",
      },
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleFinancialsChange = this.handleFinancialsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  //Button Functions =======================================
  next() {
    let cs = this.state.currentStep;
    cs++;
    this.setState({ currentStep: cs });
  }

  prev() {
    let cs = this.state.currentStep;
    cs--;
    this.setState({ currentStep: cs });
  }

  //Form handler functions =================================
  //Change company information in state
  handleCompanyChange(e) {
    const value = e.target.value;
    this.setState({
      ...this.state,
      company: {
        ...this.state.company,
        [e.target.name]: value,
      },
    });
  }

  //Change financial information in state
  handleFinancialsChange(e) {
    const value = e.target.value;
    this.setState({
      ...this.state,
      financials: {
        ...this.state.financials,
        [e.target.name]: value,
      },
    });
  }

  //Change contact information in state
  handleContactChange(e) {
    const value = e.target.value;
    this.setState({
      ...this.state,
      contact: {
        ...this.state.contact,
        [e.target.name]: value,
      },
    });
  }

  //Handle submit to the database
  handleSubmit() {
    const payload = {
      company: this.state.company,
      financials: this.state.financials,
      contact: this.state.contact,
    };
    axios.post("./api/user", payload).then((res) => {
      alert("Thank you for submitting your contact info");
      this.setState({ ...this.state, redirect: true });
    });
  }

  render() {
    const { currentStep, company, financials, contact, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <div className="row">
        <div className="col-12">
          <Steps
            currentStep={currentStep}
            company={company}
            financials={financials}
            contact={contact}
            handleCompanyChange={this.handleCompanyChange}
            handleFinancialsChange={this.handleFinancialsChange}
            handleContactChange={this.handleContactChange}
          />
        </div>

        <div className="col-12">
          <ButtonGroup
            currentStep={currentStep}
            handleNext={this.next}
            handlePrev={this.prev}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}
