import React from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Header } from "../../common";
import { TextBox, ContactForm } from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(135deg, " +
      theme.palette.primary.dark +
      ", " +
      theme.palette.primary.main +
      ");",
    padding: theme.spacing(3),
    color: theme.palette.secondary.main,
    alignContent: "flex-start",
    justifyContent: "center",
  },
  contactContainer: {
    justifyContent: "center",
  },
}));

export default function Contact() {
  const classes = useStyles();
  return (
    <Grid id="contact" item container className={classes.root}>
      <Grid item xs={12}>
        <Header title="Contact Us" subtitle="We are always here to help" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.contactContainer}
        spacing={3}
      >
        <Grid item container xs={12} sm={8} lg={4}>
          <ContactForm />
        </Grid>
      </Grid>
    </Grid>
  );
}
