import React, { useState, useEffect } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core";

import { BlogCard } from "./";

export default function BlogList() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios
      .get("https://www.api.infinitypartnerscapital.com/posts")
      .then((res) => {
        setPosts(res.data);
      });
  }, []);

  const blogCards = posts.map((post) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
        <BlogCard
          title={post.title}
          description={post.description}
          imgSrc={"https://api.infinitypartnerscapital.com" + post.coverImage.url}
          author={post.teammember}
          postId={post._id}
        />
      </Grid>
    );
  });

  return blogCards;
}
