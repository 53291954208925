//Add react library
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";

import { Container, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { AuthorBlock } from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignContent: "flex-start",
  },
  coverImage: {
    maxHeight: "50vh",
    overflow: "hidden",
    width: "100%",
    objectFit: "cover",
  },
  contentContainer: {
    alignContent: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  textContainer: {
    padding: theme.spacing(2),
  },
}));

export default function BlogPost() {
  const classes = useStyles();
  const { postId } = useParams();

  const [post, setPost] = useState({
    title: "",
    subtitle: "",
    coverImage: "",
    teammember: {
      image: "",
    },
  });

  useEffect(() => {
    axios
      .get("https://api.infinitypartnerscapital.com/posts/" + postId)
      .then((res) => {
        setPost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [postId]);

  return (
    <Container className={classes.root} maxWidth="xl" disableGutters>
      <Grid item container xs={12} sm={8} className={classes.contentContainer}>
        <Grid item xs={8}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" color="primary" align="center">
            {post.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary"
            align="center"
            gutterBottom
          >
            {post.subtitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AuthorBlock
            name={post.teammember.name}
            date={post.date}
            imgSrc={post.teammember.image.url}
          />
        </Grid>
        <Grid item xs={12}>
          <img
            src={
              "https://api.infinitypartnerscapital.com" + post.coverImage.url
            }
            alt="Cover picture"
            className={classes.coverImage}
          />
        </Grid>
        <Grid item xs={12} className={classes.textContainer}>
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </Grid>
      </Grid>
    </Container>
  );
}
