import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Hidden,
  Drawer,
  AppBar,
  Toolbar,
  Button,
  IconButton,
} from "@material-ui/core";

import { Menu } from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "flex-start",
    padding: theme.spacing(1)
  },
  drawer: {
    padding: theme.spacing(2),
    minWidth: "250px",
  },
  button: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

export default function MobileNav(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawerOpen = () => {
    setIsOpen(true);
  };

  const toggleDrawerClose = () => {
    setIsOpen(false);
  };

  const { links } = props;

  const linkButtons = links.map((link) => {
    return (
      <Button
        key={link.text}
        color="primary"
        href={link.url}
        children={link.text}
        onClick={toggleDrawerClose}
        className={classes.button}
        fullWidth
      />
    );
  });

  return (
    <Hidden mdUp>
      <Toolbar className={classes.toolbar}>
        <IconButton color="secondary" onClick={toggleDrawerOpen}>
          <Menu />
        </IconButton>
      </Toolbar>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawerClose}>
        <Grid
          container
          className={classes.drawer}
          direction="column"
          alignItems="flex-start"
        >
          {linkButtons}
        </Grid>
      </Drawer>
    </Hidden>
  );
}
