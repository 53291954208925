import React from "react";

import { Grid, Typography, Button } from "@material-ui/core";

import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
} from "@material-ui/core";

import { Link } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  cardImage: {
    height: "20vh",
    width: "100%",
    overflow: "hidden",
    backgroundSize: "contain",
  },
  cardBody: {
    height: "auto",
  },
  cardActions: {},
  iconButton: {
    display: "block",
    margin: "0 auto",
  },
}));

export default function PortfolioCard(props) {
  const classes = useStyles();
  const { imgSrc, title, description, url } = props;

  return (
    <Card className={classes.card} raised>
      <CardMedia className={classes.cardImage} image={imgSrc}></CardMedia>
      <CardContent className={classes.cardBody}>
        <Typography variant="h5" align="center" display="block" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" align="center" display="block" gutterBottom>
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <IconButton
          color="primary"
          className={classes.iconButton}
          children={<Link />}
          href={url}
        />
      </CardActions>
    </Card>
  );
}
