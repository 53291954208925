import React, { useState, useEffect } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core";

import { TeamCard } from "./";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function TeamList() {
  const classes = useStyles();
  const [members, setMembers] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://www.api.infinitypartnerscapital.com/teammembers?isFeatured=true"
      )
      .then((res) => {
        setMembers(res.data);
      });
  }, []);

  const teamCards = members.map((member) => {
    return (
      <Grid item xs={12} sm={6} md={3} key={member._id}>
        <TeamCard
          imgSrc={
            "https://www.api.infinitypartnerscapital.com" + member.image.url
          }
          name={member.name}
          role={member.role}
          specialityList={member.specialityList}
          linkedIn={member.linkedIn}
        />
      </Grid>
    );
  });

  return teamCards;
}
