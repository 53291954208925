//Add react library
import React from "react";

import { Grid, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    alignContent: "center",
  },
  avatarContainer: {
    justifyContent: "flex-end",
    alignContent: "center",
    display: "flex",
    padding: theme.spacing(1),
  },
}));

export default function AuthorBlock(props) {
  const classes = useStyles();
  const {imgSrc, name, date} = props;

  return (
    <Grid item container xs={12}>
      <Grid item xs={6} className={classes.avatarContainer}>
        <Avatar
          src={"https://api.infinitypartnerscapital.com" + imgSrc}
          alt={name}
        />
      </Grid>
      <Grid item container xs={6} className={classes.textContainer}>
        <Grid item xs={12}>
          <Typography variant="body2" color="primary">{name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="primary">{date}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
