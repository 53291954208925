import React from "react";

//import components
import CompanyDetails from "./companydetails/CompanyDetails";
import ContactDetails from "./contactdetails/ContactDetails";
import FinancialDetails from "./financialdetails/FinancialDetails";
import Results from "./results/Results";

export default class Steps extends React.Component {
  render() {
    //Deconstruct state and props
    const {
      currentStep,
      company,
      financials,
      contact,
      handleCompanyChange,
      handleFinancialsChange,
      handleContactChange,
    } = this.props;

    //Step switcher, output current step only
    switch (currentStep) {
      case 1:
        return (
          <CompanyDetails
            company={company}
            handleChange={handleCompanyChange}
          />
        );
      case 2:
        return (
          <FinancialDetails
            currentStep={currentStep}
            financials={financials}
            handleChange={handleFinancialsChange}
          />
        );
      case 3:
        return (
          <Results
            currentStep={currentStep}
            industry={company.industry}
            financials={financials}
          />
        );
      case 4:
        return (
          <ContactDetails
            currentStep={currentStep}
            contact={contact}
            handleChange={handleContactChange}
          />
        );
    }
  }
}
