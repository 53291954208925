import React from "react";
import { Link } from "react-router-dom";

import { Grid, Typography, Button } from "@material-ui/core";

import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardImage: {
    height: "40vh",
    width: "100%",
    overflow: "hidden",
    backgroundSize: "cover",
  },
  cardBody: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardActions: {
    backgroundColor: theme.palette.primary.light,
    justifyContent: "space-between",
  },
  button: {
    display: "flex",
    margin: theme.spacing(2),
  },
}));

export default function BlogCard(props) {
  const classes = useStyles();
  const { title, description, imgSrc, author, postId } = props;

  return (
    <Card className={classes.card} raised>
      <CardMedia className={classes.cardImage} image={imgSrc}></CardMedia>
      <CardContent className={classes.cardBody}>
        <Typography variant="h6" color="primary" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" align="center" gutterBottom>
          {description}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          component={Link}
          to={"/blog/" + postId}
        >
          Read More
        </Button>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Typography variant="overline">{author.name}</Typography>
        <Avatar
          src={"https://api.infinitypartnerscapital.com" + author.image.url}
          alt={author.name}
        />
      </CardActions>
    </Card>
  );
}
