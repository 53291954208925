import React, { useState, useEffect } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { RadioButtons } from "./";

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: "space-around",
  },
}));

export default function Form() {
  const classes = useStyles();
  const [contactDetails, setContactDetails] = useState({
    fullName: "",
    email: "",
    phone: "",
    company: "",
    description: "",
    service: "",
  });
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.infinitypartnerscapital.com/services")
      .then((res) => {
        setServiceOptions(res.data);
      });
  }, []);

  function handleChange(e) {
    setContactDetails({
      ...contactDetails,
      [e.target.name]: e.target.value,
    });
  }

  function handleSubmit() {
    axios
      .post("https://api.infinitypartnerscapital.com/customers", contactDetails)
      .then((res) => {
        alert(
          "Thank you for submitting your information, we will reach out soon"
        );
        setContactDetails({
          fullName: "",
          email: "",
          phone: "",
          company: "",
          description: "",
          service: "",
        });
      });
  }

  return (
    <Grid item container className={classes.root}>
      <TextField
        id="name"
        name="fullName"
        type="text"
        label="Full Name"
        value={contactDetails.name}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="email"
        name="email"
        type="email"
        label="Email"
        value={contactDetails.email}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="phone"
        name="phone"
        type="text"
        label="Phone Number"
        value={contactDetails.phone}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="company"
        name="company"
        type="text"
        label="Company"
        value={contactDetails.company}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="description"
        name="description"
        type="text"
        label="Tell us about your company"
        multiline
        rows={3}
        value={contactDetails.description}
        onChange={handleChange}
        fullWidth
      />
      <RadioButtons
        label="Which service are you interested in?"
        name="service"
        value={contactDetails.service}
        options={serviceOptions}
        handleChange={handleChange}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Grid>
  );
}
