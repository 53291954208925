import React from "react";
import ReactMarkdown from "react-markdown";

import { Grid, Typography } from "@material-ui/core";

import { Card, CardHeader, CardMedia, CardContent } from "@material-ui/core";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import { ChevronRight } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "auto",
    width: "100%",
  },
  image: {
    height: "30vh",
    backgroundSize: "cover",
  },
  cardContent: {
    height: "calc(100% - 30vh)",
  },
}));

export default function ServiceCard(props) {
  const { imgSrc, title, serviceList } = props;
  const classes = useStyles();

  const listItems = serviceList.map((service) => {
    return (
      <ListItem key={service._id}>
        <ListItemIcon children={<ChevronRight />} />
        <ListItemText primary={service.text} />
      </ListItem>
    );
  });

  return (
    <Card className={classes.card} raised>
      <CardMedia className={classes.image} image={imgSrc} title={title} />
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" align="left" color="primary">
          {title}
        </Typography>
        <List dense>{listItems}</List>
      </CardContent>
    </Card>
  );
}
