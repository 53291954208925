import React from "react";

import { Link } from "react-router-dom";

import { Grid, Typography, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: "center",
  },
  text: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
    },
  },
}));

export default function CTA() {
  const classes = useStyles();
  return (
    <Grid item container spacing={2} className={classes.root}>
      <Grid item xs={12} lg={9}>
        <Typography variant="h1" color="primary" className={classes.text}>
          Unlocking tomorrow's value, today
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Typography
          variant="h5"
          color="primary"
          className={classes.text}
          gutterBottom
        >
          We provide corporate finance advisory services to SME clients in South
          Africa
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Button
          variant="contained"
          color="primary"
          href="#contact"
          size="large"
          fullWidth
        >
          Contact
        </Button>
      </Grid>
    </Grid>
  );
}
