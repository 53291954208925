//Add react library
import React from "react";

//import router
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

//import react components
import { Navbar, Footer } from "./layout";
import {
  Main,
  Valuation,
  Team,
  Blog,
  Portfolio,
  BlogPost,
  About,
} from "./pages";

export default function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/blog/:postId">
          <BlogPost />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/team">
          <Team />
        </Route>
        <Route path="/valuation">
          <Valuation />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
      <Footer/>
    </Router>
  );
}
