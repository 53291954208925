import { useState, useEffect } from "react";
import { getLogo, getAbout, getCompanyInfo } from "./api";

export function useLogo() {
  const [logo, setLogo] = useState({});

  useEffect(() => {
    getLogo().then((res) => {
      setLogo(res.data);
    });
  }, []);

  return logo;
}

export function useAbout() {
  const [about, setAbout] = useState({});

  useEffect(() => {
    getAbout().then((res) => {
      setAbout(res.data);
    });
  }, []);

  return about;
}

export function useCompanyInfo() {
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    getCompanyInfo().then((res) => {
      setCompanyInfo(res.data);
    });
  }, []);

  return companyInfo;
}
