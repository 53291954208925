import React from "react";

import { AppBar } from "@material-ui/core";

import { DesktopNav, MobileNav } from "./";

export default function Navbar() {
  const links = [
    {
      text: "Services",
      url: "/#services",
    },
    {
      text: "Team",
      url: "/team",
    },
    {
      text: "Portfolio",
      url: "/portfolio",
    },
    {
      text: "About",
      url: "/about",
    },
    {
      text: "Blog",
      url: "/blog",
    },
    {
      text: "Contact",
      url: "/#contact",
    },
  ];

  return (
    <AppBar color="primary" position="static" elevation={0}>
      <DesktopNav links={links} />
      <MobileNav links={links} />
    </AppBar>
  );
}
