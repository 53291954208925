import React from "react";

export default class TextInput extends React.Component {
  render() {
    //Destructure props
    const {
      id,
      handleChange,
      label,
      name,
      placeholder,
      type,
      value,
      isDisabled,
    } = this.props;

    return (
      <div className="form-group">
        <label htmlFor={id} className="col-12">
          {label}
        </label>
        <div className="col-12">
          <input
            className="form-control"
            id={id}
            onChange={handleChange}
            name={name}
            placeholder={placeholder}
            type={type}
            value={value}
            disabled={isDisabled}
          ></input>
        </div>
      </div>
    );
  }
}
