import React from "react";

//import components
import NumberInput from "../common/NumberInput";
import Select from "../common/Select";

export default class FinancialDetails extends React.Component {
  render() {
    //Destructure props
    const { financials, handleChange } = this.props;
    const yearOptions = [2020, 2019, 2018];

    return (
      <div className="row">
        <div className="col-12">
          <Select
            id="year"
            handleChange={handleChange}
            label="Year"
            name="year"
            options={yearOptions}
            value={financials.year}
          />
          <NumberInput
            id="revenue"
            handleChange={handleChange}
            label="Revenue"
            name="revenue"
            placeholder="Enter your earnings for the year"
            type="number"
            value={financials.revenue}
          />
          <NumberInput
            id="ebitda"
            handleChange={handleChange}
            label="EBITDA"
            name="ebitda"
            placeholder="Enter your EBITDA"
            type="number"
            value={financials.ebitda}
          />
          <NumberInput
            id="netProfit"
            handleChange={handleChange}
            label="Net Profit"
            name="netProfit"
            placeholder="Enter your net profit"
            type="number"
            value={financials.netProfit}
          />
          <NumberInput
            id="bookValue"
            handleChange={handleChange}
            label="Book Value"
            name="bookValue"
            placeholder="Enter your book value"
            type="number"
            value={financials.bookValue}
          />
          <NumberInput
            id="investedCapital"
            handleChange={handleChange}
            label="Invested Capital"
            name="investedCapital"
            placeholder="Enter your invested capital"
            type="number"
            value={financials.investedCapital}
          />
        </div>
      </div>
    );
  }
}
