import React from "react";

import { useCompanyInfo } from "../../../services/hooks";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { Header } from "../../common";
import { TextBlock } from "./";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "70vh",
    background: theme.palette.secondary.light,
    padding: theme.spacing(2),
    alignContent: "space-around",
    color: theme.palette.primary.dark,
  },
  textContainer: {
    alignContent: "flex-start",
  },
}));

export default function About() {
  const classes = useStyles();
  const companyInfo = useCompanyInfo();

  return (
    <Grid id="about" item container className={classes.container}>
      {companyInfo.leftBlock && (
        <Grid
          item
          container
          xs={12}
          lg={6}
          justify="center"
          className={classes.textContainer}
        >
          <Header title={companyInfo.leftBlock.title} />
          <TextBlock text={companyInfo.leftBlock.text} />
        </Grid>
      )}
      {companyInfo.rightBlock && (
        <Grid
          item
          container
          xs={12}
          lg={6}
          justify="center"
          className={classes.textContainer}
        >
          <Header title={companyInfo.rightBlock.title} />
          <TextBlock text={companyInfo.rightBlock.text} />
        </Grid>
      )}
    </Grid>
  );
}
