import React from "react";
import ReactDOM from "react-dom";

//Bootstrap requirements
import "bootstrap";
//import style for site
import "./scss/main.scss";

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

//import main app
import { App } from "./js";
import theme from "./theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
