import React from "react";

import { Grid, Typography } from "@material-ui/core";


export default function TextBlock(props) {
  const { text } = props;
  return (
    <Grid item container xs={12} sm={8}>
      <Typography variant="body1" align="center">{text}</Typography>
    </Grid>
  );
}
