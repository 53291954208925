import React from "react";

export default class TextArea extends React.Component {
  render() {
    //Destructure props
    const {
      id,
      handleChange,
      label,
      name,
      placeholder,
      rows,
      value,
    } = this.props;

    return (
      <div className="form-group">
        <label htmlFor={id} className="col-12">
          {label}
        </label>
        <div className="col-12">
          <textarea
            className="form-control"
            id={id}
            onChange={handleChange}
            name={name}
            placeholder={placeholder}
            rows={rows}
            value={value}
          ></textarea>
        </div>
      </div>
    );
  }
}
