//Add react library
import React from "react";

//import react components
import Header from "./common/Header";
import MultiStepForm from "./multistepform/MultiStepForm";

export default function Valuation() {
    return (
        <div className="container-fluid pt-5 gradient-dark text-light full-height">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <Header title="Tell us about your business" />
            </div>
            <div className="col-12 col-lg-8">
              <MultiStepForm/>
            </div>
          </div>
        </div>
    );
}
